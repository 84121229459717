<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { Bar } from "vue-chartjs";
import BarChartRecette from "./BarChartRecette";
import BarChartFrais from "./BarChartFrais";
//import VueEasyLightbox from "vue-easy-lightbox";
import { apiRequest } from "@/helpers/api-call";
/*import Multiselect from "vue-multiselect";
import { MazPhoneNumberInput } from "maz-ui";
import moment from "moment"; */
//import { format } from "date-fns";
//import image from "@/assets/images/users/user.png";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  extends: Bar,
  page: {
    title: "Form Validation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, BarChartRecette, BarChartFrais },
  data() {
    return {
      title: "Suivi ges gains",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Historistique",
          active: true,
        },
      ],
      order: [
        {
          userid: "ZANSOUKPE Euphrem",
          total: "12 500 XOF",
          date: "12 Mar,2020",
        },
      ],
      ordersData: [],
      showPassword: false,
      phoneNumber: "",
      thenDay: "",
      range: {
        minlen: "",
        maxlength: "",
        between: "",
        minval: "",
        maxval: "",
        rangeval: "",
        expr: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      gain: {
        totalRecette: 0,
        totalFrais: 0,
      },
      
    };
  },
  validations: {
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },
  },

  async mounted() {
    const SuivisGains = await apiRequest(
      "GET",
      "admin/gain/info",
      undefined,
      false
    );

    if (SuivisGains && SuivisGains.data) {
      //console.log("SuivisGains:", SuivisGains.data);
      this.gain.totalRecette = SuivisGains.data.totalRecette
        ? SuivisGains.data.totalRecette
        : "0";
      this.gain.totalFrais = SuivisGains.data.totalFrais
        ? SuivisGains.data.totalFrais
        : "0";
      var dataGain = SuivisGains.data;
      dataGain.gains.forEach((element) => {
        this.dataRecette.push(element.recette);
      });

      dataGain.mois.forEach((element) => {
        this.dataMois.push(this.mois[element-1]);
      });
    }
    //console.log("this.dataRecette:", this.dataMois);
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      //console.log("this.$v:", this.$v);
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5></h5>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-card
                  header-class="bg-transparent border-primary"
                  class="border border-primary"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">
                      Total des recettes
                    </h5>
                  </template>
                  <div class=" float-right">
                    <h1 class="text-primary" style="font-size:30px;">
                      {{ this.gain.totalRecette }} XOF
                    </h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-6">
                <b-card
                  header-class="bg-transparent border-success"
                  class="border border-success"
                >
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">
                      Total des frais de réservation collectée
                    </h5>
                  </template>
                  <div class=" float-right">
                    <h1 class="text-primary" style="font-size:30px;">
                      {{ this.gain.totalFrais }} XOF
                    </h1>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body mt-3">
          <b-tabs pills justified content-class="p-3 text-muted">
            <b-tab active class="border-0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  ><i class="mdi mdi-cash mr-2"></i>Gains</span
                >
              </template>
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <h4 class=" col-md-6 card-title mb-4">
                        Total des recettes
                      </h4>
                      <div class="col-md-6">
                        <select id="">
                          <!--option value="2020">2020</option>-->
                          <option value="2021">2021</option>
                        </select>
                      </div>
                    </div>
                    <!-- Bar Chart -->
                    <BarChartRecette :height="300"/>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <h4 class=" col-md-6 card-title mb-4">
                        Aperçu des frais de réservations
                      </h4>
                      <div class="col-md-6">
                        <select id="">
                          <!--<option value="">2020</option>-->
                          <option value="">2021</option>
                        </select>
                      </div>
                    </div>
                    <!-- Bar Chart -->
                    <BarChartFrais :height="300" />
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style></style>
