<script>
import { Bar } from "vue-chartjs";
import { apiRequest } from "@/helpers/api-call";

export default {
  props: {},
  extends: Bar,
  data() {
    return {
      dataGain: null,
      dataRecette: [],
      mois: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      dataMois: [],
    };
  },
  async mounted() {
    const SuivisGains = await apiRequest(
      "GET",
      "admin/gain/info",
      undefined,
      false
    );
    console.log('SuivisGains:', SuivisGains)
    if (SuivisGains && SuivisGains.data) {
      var dataGain = SuivisGains.data;

      dataGain.gains.forEach((element) => {
        this.dataRecette.push(element.frais);
      });

      dataGain.mois.forEach((element) => {
        this.dataMois.push(this.mois[element - 1]);
      });
      
      this.renderChart(
        {
          labels: this.dataMois,
          datasets: [
            {
              label: 'Frais de Réservation',
              barPercentage: 0.4,
              backgroundColor: "rgba(28, 187, 140, 0.8)",
              borderColor: "rgba(28, 187, 140, 0.8)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(28, 187, 140, 0.9)",
              hoverBorderColor: "rgba(28, 187, 140, 0.9)",
              data: this.dataRecette, //[65, 59, 81, 45, 56, 80, 50, 20]
            },
          ],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "rgba(166, 176, 207, 0.1)",
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  color: "rgba(166, 176, 207, 0.1)",
                },
              },
            ],
          },
        }
      );
    }
  },
};
</script>
